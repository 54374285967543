// @flow
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from '@reach/router';
import styled from 'styled-components';
import Img from 'gatsby-image';

import type { Meta } from '../types';
import { paths, communityUrl, titleWordSlides } from '../../config';
import { pluralize } from '../utils';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import {
  IntroText,
  Button,
  Container,
  Quote,
} from '../styles';
import WordSlider from '../components/WordSlider';
import NewsletterForm from '../components/NewsletterForm';
import Link from '../components/Link';
import HorizontalSlider from '../components/HorizontalSlider';
import PostListItem from '../components/PostListItem';
import RelatedArticleListItem from '../components/RelatedArticleListItem';
import Tags from '../components/Tags';
import AlternativeCategory from '../components/AlternativeCategory';

const Section = styled.section`
  padding: 46px 0;

  @media (min-width: 768px) {
    padding: 66px 0;
  }
`;

const StyledImg = styled(Img)``;

const HeroSection = styled(Section)`
  background: #ffe9e9;

  ${StyledImg} {
    min-height: 298px;
    margin-left: calc(-1 * var(--container-gutter-width));
    margin-right: calc(-1 * var(--container-gutter-width));
  }

  h1 {
    margin-bottom: 0;
  }

  ${Button} {
    margin-top: 6px;
  }

  a {
    --underline-color: rgb(255, 190, 190);
  }

  @media (min-width: 768px) {
    position: relative;
    padding-bottom: 241px;
    margin-bottom: 140px;

    h1 {
      margin-bottom: 1rem;
    }

    ${StyledImg} {
      position: absolute !important;
      top: 43.5%;
      left: var(--container-gutter-width);
      width: 795px;
      height: 473px;
      margin: 0;
    }

    ${Button} {
      margin-top: 1rem;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 86px;
    padding-bottom: 120px;

    ${StyledImg} {
      top: 14px;
      left: 42%;
      width: 998px;
      height: 594px;
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 490px;

  ${IntroText} span {
    color: #000;
  }
`;

const HeroTitle = styled.h1`
  > span {
    display: block;
    white-space: nowrap;
  }
`;

const PopularCategoriesSection = styled(Section)``;
const StyledAlternativeCategory = styled(AlternativeCategory)`
  flex: 0 0 243px;

  & + & {
    margin-left: var(--grid-gutter-width);
  }
`;

const PopularCategoriesLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

const PopularAlternativesSection = styled(Section)``;

const AlternativeListItem = styled(PostListItem).attrs((props) => ({
  isGuide: props.item.type === 'guide',
}))`
  margin: 0;
  padding: 10px 10.5px 15px;
  flex: 0 0 243px;

  .gatsby-image-wrapper {
    width: ${(props) => (props.isGuide ? '222px' : '243px')};
    height: 162px;
  }

  @media (min-width: 768px) {
    padding: 14px 13.5px 20px;
    flex-basis: 308px;

    .gatsby-image-wrapper {
      width: ${(props) => (props.isGuide ? '281px' : '308px')};
      height: 205px;
    }
  }

  @media (min-width: 1200px) {
    padding: 19px 18.5px 30px;
    flex-basis: 418px;

    .gatsby-image-wrapper {
      width: ${(props) => (props.isGuide ? '381px' : '418px')};
      height: 278px;
    }
  }
`;

const TestimonialsSection = styled(Section)`
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 100px;
  }

  ${IntroText} {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Testimonials = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 45px;
  margin-left: calc(-1 * var(--grid-gutter-width) / 2);
  margin-right: calc(-1 * var(--grid-gutter-width) / 2);
  text-align: left;

  @media (min-width: 768px) {
    margin-top: 69px;
  }

  @media (min-width: 1200px) {
    margin-top: 134px;
  }
`;

const Testimonial = styled.div`
  width: 100%;
  padding: 16px calc(var(--grid-gutter-width) / 2);

  ${StyledImg} {
    margin-right: 14px;
    border-radius: 50%;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 33.3337%;
  }
`;

const TestimonialFooter = styled.div`
  display: flex;
  margin-top: 9px;
  align-items: center;
  color: #8e8e8e;

  @media (min-width: 768px) {
    margin-top: 15px;
  }
`;

const TestimonialName = styled.span`
  color: #000;
  font-weight: 500;
`;

const TestimonialDate = styled.div`
  font-size: 13px;
`;

const WhatIsEthicalSection = styled(Section)`
  ${StyledImg} {
    min-height: 327px;
    margin: -24px calc(-1 * var(--container-gutter-width)) 50px;
  }

  ${IntroText} {
    position: relative;
    margin-top: 30px;
    z-index: 2;
  }

  @media (min-width: 768px) {
    ${StyledImg} {
      width: 1037px;
      height: auto;
      margin-left: -60px;
      margin-right: auto;
    }
  }

  @media (min-width: 992px) {
    ${StyledImg} {
      margin-left: 0;
    }
  }

  @media (min-width: 1200px) {
    ${IntroText} {
      margin-top: 40px;
    }
  }
`;

const CommunityComments = styled.div``;

const CommunityComment = styled.div`
  margin-top: 26px;

  ${Quote} {
    margin-bottom: 4px;
    max-width: 370px;
  }
`;

const CommunityCommentFooter = styled.div`
  color: #8e8e8e;
  max-width: 370px;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

const ResourcesSection = styled(Section)`
  margin-top: 70px;
  text-align: center;
  background: rgba(156, 208, 185, 0.26);

  ${StyledImg} {
    height: 411px;
    margin-top: -146px;
  }

  h2 {
    margin-top: 33px;
  }

  ${IntroText} {
    max-width: 496px;
    margin: 0 auto;
    margin-bottom: 36px;
  }

  @media (min-width: 768px) {
    margin-top: 208px;
    padding-bottom: 75px;

    ${StyledImg} {
      max-width: 1150px;
      height: 803px;
      margin-top: -252px;
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      margin-top: 40px;
    }

    ${IntroText} {
      margin-bottom: 59px;
    }
  }
`;

const ResourcesButtonGroup = styled.div`
  ${Button} + ${Button} {
    margin-left: 26px;
  }

  @media (min-width: 768px) {
    ${Button} + ${Button} {
      margin-left: 65px;
    }
  }
`;

const AlternativeTagsSection = styled(Section)`
  padding-top: 77px;

  ${Container} {
    position: relative;
  }

  h2 {
    max-width: 569px;
  }

  ${StyledImg} {
    display: none !important;
  }

  @media (min-width: 768px) {
    padding-top: 133px;

    ${StyledImg} {
      display: block !important;
      position: absolute !important;
      top: -300px;
      left: 75%;
      width: 527px;
      height: 669px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 193px;

    ${StyledImg} {
      right: -134px;
      left: auto;
    }
  }
`;

const StyledTags = styled(Tags)`
  margin-top: 23px;
  margin-bottom: 7px;

  @media (min-width: 768px) {
    margin-top: 46px;
    margin-bottom: 12px;
  }

  @media (min-width: 1200px) {
    margin-top: 88px;
  }
`;

const AlternativeTagArchiveLink = styled(Link)`
  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

const GoodReadsSection = styled(Section)`
  padding-bottom: 0 !important;

  @media (min-width: 1200px) {
    padding-top: 108px;
  }
`;

const NewsletterSection = styled(Section)`
  display: none;

  h1 {
    margin-bottom: 31px;
  }

  form {
    margin-top: 51px;
  }

  @media (min-width: 1200px) {
    display: block;
    max-width: 558px;
    margin: 217px auto 255px;
    text-align: center;
  }
`;

type Props = {
  data: {
    page: {
      yoastMeta: Meta[],
      customMeta: {
        title: string,
        canonical: string,
      },
      acf: {
        popularCategories: Array<{
          name: string,
          path: string,
          count: number,
          acf?: {
            featuredImage?: {
              alt: string,
              localFile?: {
                childImageSharp: {
                  fluid: any,
                },
              },
            },
          },
        }>,
        popularAlternatives: Array<{
          id: string,
          title: string,
          path: string,
          excerpt: string,
          type: string,
          featuredImage?: {
            alt: string,
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
          },
          tags?: Array<{
            path: string,
            name: string,
          }>,
          customMeta: {
            commentCount: number,
          },
        }>,
        testimonials?: Array<{
          avatar?: {
            localFile?: {
              childImageSharp: {
                fixed: any,
              },
            },
          },
          name: string,
          date: string,
          itemReviewed: string,
          rating: number,
          text: string,
        }>,
        communityComments?: Array<{
          comment: string,
          date: string,
          name: string,
        }>,
      },
    },
    heroImage: {
      childImageSharp: {
        fluid: any,
      },
    },
    illustrationBalance: {
      childImageSharp: {
        fluid: any,
      },
    },
    illustrationMeditate: {
      childImageSharp: {
        fluid: any,
      },
    },
    circlesImage: {
      childImageSharp: {
        fixed: any,
      },
    },
    latestPosts: {
      edges: Array<{
        node: {
          id: string,
          path: string,
          title: string,
          excerpt: string,
          categories?: Array<{
            name: string,
          }>,
          featuredImage?: {
            alt: string,
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
          },
        },
      }>,
    },
    alternatives: {
      totalCount: number,
    },
    alternativeCategories: {
      totalCount: number,
    },
    alternativeTags: {
      edges: Array<{
        node: {
          name: string,
          path: string,
        },
      }>,
    },
  },
};

const Home = ({
  data: {
    page,
    heroImage,
    illustrationBalance,
    illustrationMeditate,
    circlesImage,
    latestPosts,
    alternatives,
    alternativeCategories,
    alternativeTags,
  },
}: Props) => {
  useEffect(() => {
    // Redirect WordPress search URL format to new search page
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const searchTerm = urlParams.get('s');
      if (searchTerm) {
        navigate(`/search?s=${searchTerm}`);
      }
    }
  }, []);

  return (
    <Layout fullwidth noHeaderBottomSpace>
      <SEO
        title={page.customMeta.title}
        canonical={page.customMeta.canonical}
        meta={page.yoastMeta}
      />

      <HeroSection>
        <Container>
          <HeroContent>
            <HeroTitle className="h1-plus">
              <span>
                <>Let&rsquo;s make </>
                <WordSlider slides={titleWordSlides} />
              </span>
              <span>the new normal</span>
            </HeroTitle>
          </HeroContent>
          <StyledImg
            fluid={heroImage.childImageSharp.fluid}
            alt=""
            loading="eager"
          />
          <HeroContent>
            <IntroText>
              We are working hard to create a place where we can
              discover ethical alternatives,
              <span>
                <> we currently have </>
                <Link to={paths.alternatives}>
                  {`${alternatives.totalCount} ${pluralize(alternatives.totalCount, 'option', 'options')}`}
                </Link>
                !
              </span>
            </IntroText>
            <IntroText>
              Do you know one?
            </IntroText>
            <Button size="large" as={Link} to={`${paths.alternatives}#add-item-modal`}>Add it!</Button>
          </HeroContent>
        </Container>
      </HeroSection>

      <PopularCategoriesSection>
        <Container>
          <HorizontalSlider
            title="Popular categories"
            subtitle="We are defined by what we do, create, buy or repair."
            footer={alternativeCategories.totalCount > page.acf.popularCategories.length && (
              <PopularCategoriesLink to={paths.alternativeCategories}>
                {`See all ${alternativeCategories.totalCount} alternative categories`}
              </PopularCategoriesLink>
            )}
          >
            {page.acf.popularCategories.map((cat) => {
              if (!cat.acf || !cat.acf.featuredImage) {
                return null;
              }

              return <StyledAlternativeCategory key={cat.path} category={cat} />;
            })}
          </HorizontalSlider>
        </Container>
      </PopularCategoriesSection>

      <PopularAlternativesSection>
        <Container>
          <HorizontalSlider
            title="Popular alternatives"
            subtitle="These are some top notch options, we can’t say much else!"
            footer={alternatives.totalCount > page.acf.popularAlternatives.length && (
              <PopularCategoriesLink to={paths.alternatives}>
                {`See all ${alternatives.totalCount} alternatives`}
              </PopularCategoriesLink>
            )}
          >
            {page.acf.popularAlternatives.map((alternative) => (
              <AlternativeListItem key={alternative.id} item={alternative} />
            ))}
          </HorizontalSlider>
        </Container>
      </PopularAlternativesSection>

      <TestimonialsSection>
        <Container>
          <h2 className="h1">What people are saying</h2>
          <IntroText small>
            This platform is made by people like you,
            who share their ideas, opinions and experiences.
          </IntroText>
          <Testimonials>
            {page.acf.testimonials && page.acf.testimonials.map((testimonial, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Testimonial key={i} itemProp="review" itemScope itemType="http://schema.org/Review">
                <Quote>{testimonial.text}</Quote>
                <TestimonialFooter>
                  {testimonial.avatar && testimonial.avatar.localFile && (
                    <div>
                      <StyledImg fixed={testimonial.avatar.localFile.childImageSharp.fixed} alt={`${testimonial.name} avatar`} />
                    </div>
                  )}
                  <div>
                    <TestimonialName itemProp="name">{testimonial.name}</TestimonialName>
                    <> about </>
                    <span itemProp="itemReviewed">{testimonial.itemReviewed}</span>
                    <meta itemProp="datePublished" content={testimonial.date} />
                    <meta itemProp="ratingValue" content={testimonial.rating} />
                    <TestimonialDate>{testimonial.date}</TestimonialDate>
                  </div>
                </TestimonialFooter>
              </Testimonial>
            ))}
          </Testimonials>
        </Container>
      </TestimonialsSection>

      <WhatIsEthicalSection>
        <Container>
          <h2 className="h1-plus">What is ethical anyway?</h2>
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <IntroText>
                We know that “ethical” isn’t an objective word — it
                can mean different things to different people.
              </IntroText>
              <IntroText>
                For us, ethical means moving in the direction of
                least possible harm against other people, animals and the planet.
              </IntroText>
              <IntroText>
                What do you think? Share it in&nbsp;
                <Link to={communityUrl}>our forum</Link>
                .
              </IntroText>
            </div>
            <div className="col-md-6 col-lg-5">
              <StyledImg fluid={illustrationBalance.childImageSharp.fluid} alt="" />
            </div>
          </div>
          <CommunityComments className="row justify-content-center">
            {page.acf.communityComments && page.acf.communityComments
              .map(({ name, comment, date }) => (
                <CommunityComment key={name} className="col-md-6 col-lg-4">
                  <Quote>{comment}</Quote>
                  <CommunityCommentFooter>
                    {`${date} by ${name}`}
                  </CommunityCommentFooter>
                </CommunityComment>
              ))}
          </CommunityComments>
        </Container>
      </WhatIsEthicalSection>

      <ResourcesSection>
        <StyledImg fluid={illustrationMeditate.childImageSharp.fluid} alt="" />
        <Container>
          <h2 className="h1-plus">Resources</h2>
          <IntroText>
            We’ve compiled an open list of resources for
            ethical living — from books to browser
            extensions, TED talks to apps.
          </IntroText>
          <ResourcesButtonGroup>
            <Button size="large" as={Link} to={paths.resources}>Check it out</Button>
            <Button size="large" as={Link} to={`${paths.resources}#add-item-modal`}>Contribute</Button>
          </ResourcesButtonGroup>
        </Container>
      </ResourcesSection>

      <AlternativeTagsSection>
        <Container>
          <StyledImg fixed={circlesImage.childImageSharp.fixed} alt="" />
          <h2 className="h1-plus">Something specific that you care about?</h2>
          <StyledTags tags={alternativeTags.edges.map(({ node }) => node)} />
          <AlternativeTagArchiveLink to={paths.alternativeTags}>
            See all topics
          </AlternativeTagArchiveLink>
        </Container>
      </AlternativeTagsSection>

      <GoodReadsSection>
        <Container>
          <HorizontalSlider
            title="Good reads"
            subtitle="Do you know it all? No… us neither. We want to keep learning."
          >
            {latestPosts.edges.map(({ node }) => (
              <RelatedArticleListItem key={node.id} item={node} />
            ))}
          </HorizontalSlider>
        </Container>
      </GoodReadsSection>

      <NewsletterSection>
        <Container>
          <h2 className="h1">The Newsletter</h2>
          <IntroText>
            Once a month the latest ethical alternatives, ideas and reviews…
            right in your mailbox.
          </IntroText>
          <IntroText>
            Don’t worry, we’re not going to be annoying.
          </IntroText>
          <NewsletterForm large inline />
        </Container>
      </NewsletterSection>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    page: wordpressPage(path: {eq: "/"}) {
      yoastMeta: yoast_meta {
        name
        property
        content
      }
      customMeta: et_custom_meta {
        title
        canonical
      }
      acf {
        popularCategories: popular_categories {
          name
          path
          count
          acf {
            featuredImage: featured_image {
              alt: alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        popularAlternatives: popular_alternatives {
          ... on wordpress__wp_alternatives {
            id
            title
            path
            excerpt
            type
            featuredImage: featured_media {
              alt: alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 418) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            tags: alternative_tags {
              path
              name
            }
            customMeta: et_custom_meta {
              commentCount: comment_count
            }
          }
          ... on wordpress__wp_guides {
            id
            title
            path
            excerpt
            type
            featuredImage: featured_media {
              alt: alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            tags: alternative_tags {
              path
              name
            }
            customMeta: et_custom_meta {
              commentCount: comment_count
            }
          }
        }
        testimonials {
          avatar {
            localFile {
              childImageSharp {
                fixed(width: 45, height: 45) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          name
          date(fromNow: true)
          itemReviewed: item_reviewed
          rating
          text
        }
        communityComments: featured_comments_from_community {
          comment
          date(fromNow: true)
          name
        }
      }
    }
    heroImage: file(relativePath: { eq: "homepage-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 998) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustrationBalance: file(relativePath: { eq: "illustration-balance.png" }) {
      childImageSharp {
        fluid(maxWidth: 1037) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustrationMeditate: file(relativePath: { eq: "illustration-meditate.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    circlesImage: file(relativePath: { eq: "circles.png" }) {
      childImageSharp {
        fixed(width: 527, height: 669) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    latestPosts: allWordpressPost(sort: {order: DESC, fields: date}, limit: 5) {
      edges {
        node {
          id
          path
          title
          excerpt
          categories {
            name
          }
          featuredImage: featured_media {
            alt: alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 310) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    alternatives: allWordpressWpAlternatives {
      totalCount
    }
    alternativeCategories: allWordpressWpAlternativeCategories {
      totalCount
    }
    alternativeTags: allWordpressWpAlternativeTags(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}, limit: 12) {
      edges {
        node {
          name
          path
        }
      }
    }
  }
`;
