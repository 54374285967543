/**
 * Renders an inline list of tag links
 *
 * Used for alternative item tags (large font size) at the moment
 * but can be customized to support small size tags in the PostListItem
 * component
 *
 * @flow
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Link from './Link';

const Wrapper = styled.div`
  margin: 0 -9px;

  @media (min-width: 768px) {
    margin: 0 -15px;
  }
`;

const Tag = styled.span`
  display: inline-block;
  margin: 7px 9px;

  @media (min-width: 768px) {
    font-size: 21px;
    margin: 12px 15px;
  }
`;

const TagLink = styled(Link)`
  color: ${(props) => (props.activeTag && !props.active ? '#9b9b9b' : 'var(--color-link)')};
`;

type Props = {
  tags: Array<{
    path: string,
    name: string,
  }>,
  activeTag?: {
    path: string,
    name: string,
  },
  className?: string,
  onTagClick?: (path: string) => void,
};

const defaultProps = {
  activeTag: undefined,
  className: undefined,
  onTagClick: undefined,
};

const Tags = ({
  tags,
  activeTag,
  className,
  onTagClick,
}: Props) => {
  const handleClick = useCallback((event: SyntheticEvent<HTMLAnchorElement>, path: string) => {
    if (onTagClick && typeof onTagClick === 'function') {
      event.preventDefault();
      onTagClick(path);
    }
  }, [onTagClick]);

  return (
    <Wrapper className={className}>
      {tags.map(({ path, name }) => (
        <Tag key={path}>
          <TagLink
            to={path}
            activeTag={activeTag}
            active={activeTag && activeTag.path === path}
            onClick={(event) => handleClick(event, path)}
          >
            {name}
          </TagLink>
        </Tag>
      ))}
    </Wrapper>
  );
};

Tags.defaultProps = defaultProps;

export default Tags;
