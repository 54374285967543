// @flow
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import classnames from 'classnames';

import Link from './Link';
import { IntroText } from '../styles';

const StyledImg = styled(Img)`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 8px 11px inset #000;

  img {
    opacity: 0.88 !important;
  }

  @media (min-width: 1200px) {
    flex: 0 0 310px;
    height: 206px;
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: -58px;
  padding-top: 28px;
  transition: transform 0.4s cubic-bezier(0.15, 0.53, 0.35, 1);
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -353px;
    width: 850px;
    height: 850px;
    background: var(--color-background-post-body, #fff);
    border-radius: 50%;
    box-shadow: -6px 0px 10px rgba(0, 0, 0, 0.03);
    z-index: -1;
  }

  @media (min-width: 768px) {
    &::before {
      left: -500px;
      width: 1200px;
      height: 1200px;
    }
  }

  @media (min-width: 1200px) {
    margin-top: 0;
    margin-left: -126px;
    padding-left: 58px;

    &::before {
      top: -50px;
      left: 10px;
      width: 400px;
      height: 400px;
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 0 0 242px;
  box-shadow: none !important;

  & + & {
    margin-left: 16px;
  }

  &:hover ${Content} {
    transform: translateY(-15px);
  }

  @media (min-width: 768px) {
    flex-basis: 308px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    flex-basis: 570px;

    & + & {
      margin-left: 32px;
    }

    &:hover ${Content} {
      transform: translateX(-15px);
    }
  }
`;

const Title = styled.h4``;

const Excerpt = styled.div`
  p:last-of-type {
    margin-bottom: 0;
  }
`;

type Props = {
  item: {
    title: string,
    excerpt: string,
    path: string,
    featuredImage?: {
      alt: string,
      localFile?: {
        childImageSharp: {
          fluid: any,
        }
      },
    },
    categories?: Array<{
      name: string,
    }>,
  },
  className?: string,
};

const defaultProps = {
  className: undefined,
};

const RelatedArticleListItem = ({
  item: {
    path,
    title,
    excerpt,
    categories,
    featuredImage,
  },
  className,
}: Props) => {
  const truncatedTitle = title.length > 56 ? `${title.substring(0, 53)}…` : title;

  return (
    <StyledLink to={path} className={classnames('hentry', className)} rel="bookmark">
      {featuredImage && featuredImage.localFile && (
        <StyledImg
          fluid={featuredImage.localFile.childImageSharp.fluid}
          alt={featuredImage.alt}
        />
      )}
      <Content>
        {categories && categories.length > 0 && (
          <IntroText
            small
            as="span"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: categories[0].name }}
          />
        )}
        {/* eslint-disable-next-line react/no-danger */}
        <Title dangerouslySetInnerHTML={{ __html: truncatedTitle }} />
        {/* eslint-disable-next-line react/no-danger */}
        <Excerpt className="entry-summary" dangerouslySetInnerHTML={{ __html: excerpt }} />
      </Content>
    </StyledLink>
  );
};

RelatedArticleListItem.defaultProps = defaultProps;

export default RelatedArticleListItem;
