// @flow
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Link from './Link';
import { pluralize } from '../utils';

const StyledImg = styled(Img)`
  height: 305px;
  border-radius: 4px;
`;

const Category = styled(Link)`
  position: relative;
  display: block;
  height: 305px;
  box-shadow: none;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.15, 0.53, 0.35, 1);

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.35);
  }
`;

const PlaceholderImg = styled.div`
  height: 305px;
  background: #ebf5f1;
  border-radius: 4px;
`;

const CategoryContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 119px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  border-radius: 4px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.61) 0%, transparent 100%);

  span:first-of-type {
    font-size: 21px;
    font-weight: 500;
  }
`;

type Props = {
  category: {
    name: string,
    path: string,
    count: number,
    acf?: {
      featuredImage?: {
        alt: string,
        localFile?: {
          childImageSharp: {
            fluid: any,
          },
        },
      },
    },
  },
  className?: string,
};

const defaultProps = {
  className: '',
};

const AlternativeCategory = ({ category, className }: Props) => {
  const imgMarkup = category.acf
      && category.acf.featuredImage
      && category.acf.featuredImage.localFile
    ? (
      <StyledImg fluid={category.acf.featuredImage.localFile.childImageSharp.fluid} alt="" loading="eager" />
    ) : (
      <PlaceholderImg />
    );

  return (
    <Category className={className} to={category.path}>
      {imgMarkup}
      <CategoryContent>
        <span>{category.name}</span>
        <span>{`${category.count} ${pluralize(category.count, 'option', 'options')}`}</span>
      </CategoryContent>
    </Category>
  );
};

AlternativeCategory.defaultProps = defaultProps;

export default AlternativeCategory;
