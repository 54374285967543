// @flow
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const slideWord = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  10% {
    opacity: 1;
    transform: translateY(0px)
  }
  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
`;

const Wrapper = styled.span`
  display: inline;
`;

const Word = styled.span`
  position: absolute;
  padding-left: 10px;
  opacity: 0;
  overflow: hidden;
  animation: ${slideWord} 5s;
`;

type Props = {
  slides: Array<{
    word: string,
    color: string,
  }>,
};

const WordSlider = ({ slides = [] }: Props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextSlideIndex = currentSlideIndex < slides.length - 1
        ? currentSlideIndex + 1
        : 0;
      setCurrentSlideIndex(nextSlideIndex);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlideIndex]);

  const currentSlide = slides[currentSlideIndex];

  return (
    <Wrapper>
      <Word
        key={currentSlide.word}
        style={{
          color: currentSlide.color,
        }}
      >
        {currentSlide.word}
      </Word>
    </Wrapper>
  );
};

export default WordSlider;
