// @flow
import * as React from 'react';
import styled from 'styled-components';

import { TextButton, IntroText } from '../styles';
import AngleLeftIcon from '../images/angle-left.svg';
import AngleRightIcon from '../images/angle-right.svg';

const Wrapper = styled.div`
  position: relative;
  margin-right: calc(-1 * var(--container-gutter-width));

  @media (min-width: 1200px) {
    margin-left: ${(props) => (props.leftSpaceOnDesktop ? '8.3334%' : '0')}
  }

  @media (min-width: 1320px) { /* 1320px is --max-content-width but we can't use variables in media queries */
    margin-right: calc(-1 * ((100vw - var(--max-content-width)) / 2) - var(--container-gutter-width));
  }
`;

const Header = styled.div`
  display: flex;
  margin-right: var(--container-gutter-width);
`;

const Headings = styled.div`
  flex: 1;
  margin-bottom: 18px;

  h2 {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 26px;

    ${IntroText} {
      font-size: 19px;
    }
  }
`;

const NavButtons = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: ${(props) => (props.show ? 'block' : 'none')};

    ${TextButton} {
      padding: 10px;
    }
  }
`;

const Slides = styled.div`
  display: flex;
  padding: var(--container-gutter-width);
  margin: calc(-1 * var(--container-gutter-width)); /* to make box shadow work in child elements */
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::after {
    content: '';
    display: block;
    height: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: ${(props) => (props.isScrollable ? 'calc(2 * var(--container-gutter-width))' : '0')}
  }
`;

type Props = {
  title: string,
  subtitle: string,
  className?: string,
  children: React.Node,
  leftSpaceOnDesktop?: boolean,
  footer?: React.Node,
};

const defaultProps = {
  className: '',
  leftSpaceOnDesktop: true,
  footer: null,
};

const HorizontalSlider = ({
  title,
  subtitle,
  className,
  children,
  leftSpaceOnDesktop,
  footer,
}: Props) => {
  const [isScrollable, setIsScrollable] = React.useState(false);
  const [prevDisabled, setPrevDisabled] = React.useState(false);
  const [nextDisabled, setNextDisabled] = React.useState(true);

  const slidesRef = React.createRef();

  const updateNavButtons = (el) => {
    if (!el) {
      return;
    }

    const { width } = el.getBoundingClientRect();
    const { scrollWidth, scrollLeft } = el;

    if (width === scrollWidth) {
      setIsScrollable(false);
      return;
    }

    setIsScrollable(true);

    if (width + scrollLeft === scrollWidth) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    if (scrollLeft === 0) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }
  };

  const scrollHorizontally = (direction) => {
    if (!slidesRef.current) {
      return;
    }

    const { scrollWidth } = slidesRef.current;
    const itemWidth = (scrollWidth / React.Children.count(children));
    const x = direction === 'left' ? -itemWidth : itemWidth;

    slidesRef.current.scrollBy(x, 0);
  };

  React.useEffect(() => updateNavButtons(slidesRef.current), [children]);

  React.useEffect(() => {
    if (!slidesRef.current) {
      return undefined;
    }

    // save current ref in const here so that it's available in function closure scope
    const el = slidesRef.current;

    let timerId;

    const debounceUpdate = () => {
      // Clear our timeout throughout the scroll
      clearTimeout(timerId);
      // Set a timeout to run after scrolling ends
      timerId = setTimeout(() => {
        updateNavButtons(el);
      }, 66);
    };

    window.addEventListener('resize', debounceUpdate, false);
    el.addEventListener('scroll', debounceUpdate, false);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      window.removeEventListener('scroll', debounceUpdate, false);

      if (el) {
        el.removeEventListener('scroll', debounceUpdate, false);
      }
    };
  }, []);

  return (
    <Wrapper className={className} leftSpaceOnDesktop={leftSpaceOnDesktop}>
      <Header>
        <Headings>
          <h2 className="h3">{title}</h2>
          <IntroText small>{subtitle}</IntroText>
        </Headings>
        <NavButtons show={isScrollable}>
          <TextButton disabled={prevDisabled} onClick={() => scrollHorizontally('left')}>
            <AngleLeftIcon />
          </TextButton>
          <TextButton disabled={nextDisabled} onClick={() => scrollHorizontally('right')}>
            <AngleRightIcon />
          </TextButton>
        </NavButtons>
      </Header>
      <Slides ref={slidesRef} isScrollable={isScrollable}>
        {children}
      </Slides>
      {footer}
    </Wrapper>
  );
};

HorizontalSlider.defaultProps = defaultProps;

export default HorizontalSlider;
